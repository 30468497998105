import React, { useEffect } from "react"
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Layout, Heading, GameCard, NewsCard, LinkButton, Seo } from "../components";
import { isBrowser } from '../components/Layout/Layout';
import { Brand } from './about';
import { gameData } from './games';
import { RESERVE_SPECIAL_LINK } from './special';
import { useAirtableData } from "../dynamicData";
import Scrolldown from '../images/scrolldown.svg';
import NavPrev from '../images/nav-prev.svg';
import NavNext from '../images/nav-next.svg';
import Viewmore from '../images/viewmore.svg';
import SampleImg from '../images/about/sample.webp';

const Carousel = () => {
  return (
    <Swiper
      initialSlide={1}
      slidesPerView="auto"
      centeredSlides={true}
      loop={true}
    >
      {gameData.map(data => (
        <SwiperSlide key={`carousel-${data.title}`}>
          <GameCard {...data} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const Divider = () => <div className="w-10/12 h-16 mx-auto border-b border-primary-heavy" />;

const IndexPage = () => {
  const { newsData } = useAirtableData(3);
  // console.log(newsData);

  useEffect(() => {
    if (isBrowser) window.addEventListener('scroll', handleScroll);

    return () => {
      if (isBrowser) window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    if (isBrowser) {
      window.scrollBy({
        top: window.innerHeight,
        behavior: 'smooth'
      });

      window.removeEventListener('scroll', handleScroll);
    }
  }

  const handleSlideNavPrev = () => {
    const swiper = document.querySelector('.swiper').swiper;
    swiper.slidePrev();
  };

  const handleSlideNavNext = () => {
    const swiper = document.querySelector('.swiper').swiper;
    swiper.slideNext();
  };

  return (
    <Layout>
      <Seo />
      <section id="hero">
        <img 
          src={Scrolldown} 
          alt="scroll down" 
          className="absolute bottom-14 inset-x-1/2 md:left-auto md:right-20" 
          onClick={handleScroll}
        />
      </section>
      <Brand />
      <Divider />
      <div className="">
        <div className="pt-24 pb-8 flex flex-col items-center">
          <Heading variant="h1">QhAt Games</Heading>
          <p className="mt-8 mb-4 text-grey md:text-lg leading-7">
            最精彩的遊戲，盡在 QhAt 帽子烤工廠
          </p>
          <p className="text-grey md:text-lg leading-7">
            *遊戲最高難度為 Level 5 
          </p>
        </div>
        <div className="py-8">
          <Carousel />
        </div>
        <div className="w-10/12 mx-auto py-8 flex justify-between">
          <button className="p-2" onClick={handleSlideNavPrev}>
            <img src={NavPrev} alt="previous"/>
          </button>
          <button className="p-2" onClick={handleSlideNavNext}>
            <img src={NavNext} alt="next" />
          </button>
        </div>
      </div>
      <Divider />
      <div className="">
        <div className="pt-24 pb-8 flex justify-center">
          <Heading variant="h1">QhAt News</Heading>
        </div>
        <div className="flex flex-col md:flex-row gap-16 justify-start lg:justify-center items-center py-16 overflow-x-scroll">
          {newsData.map(data => <NewsCard key={data.id} {...data} />)}
        </div>
        <div className="py-16 md:px-20 flex justify-center">
          <Link to="/news">
            <img src={Viewmore} alt="view more" />
          </Link>
        </div>
      </div>
      <Divider />
      <div className="py-32 md:py-40">
        <div className="md:container md:mx-auto md:px-24 flex flex-col md:flex-row-reverse flex-center gap-16">
          <div className="w-full lg:w-1/2 flex justify-center">
            <img src={SampleImg} alt="特殊活動" className="w-full md:w-[528px] rounded aspect-[3/2] object-contain"/>
          </div>
          <div className="px-8 md:px-0 lg:w-1/2 flex flex-col items-center md:items-start">
            <Heading variant="h2">Qhat 特殊活動</Heading>
            <p className="pt-11 pb-14 md:pb-20 text-grey md:text-lg">
              生日求婚、家族聚會、好友出遊、企業團建，來體驗一場最與眾不同的活動吧！任何客製化的驚喜歡迎來QhAt！
            </p>
            <div className="w-full flex justify-center">
              <LinkButton href={RESERVE_SPECIAL_LINK}>
                預約你的專屬活動
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
