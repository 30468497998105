import React from "react"
import { Layout, Heading, LinkButton, Seo } from "../components";
import Flower from '../images/special/flower.svg';
import Hug from '../images/special/hug.svg';
import Box from '../images/special/box.svg';
import Party from '../images/special/party.webp';
import Enterprise from '../images/special/enterprise.webp';
import Customization from '../images/special/customization.webp';

const metaData = {
  title: "QhAt帽子烤密室工廠 2022必玩密室逃脫",
  description: "還在為如何替好友準備驚喜而煩惱嗎?或是家庭出遊卻擔心氣候不佳而取消嗎?又或是公司團建找不到合適的場地?快來找我們吧！不論是生日、求婚、家族聚會、好友出遊、公司團建，甚至是客製化謎題設計、謎題包規劃，都可以替大家安排喔！",
  image: process.env.ROOT_URL + 'box.webp'
}

export const RESERVE_SPECIAL_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSfAv8m02Ki-M4SgQ2VHhwjzD6VTZ8T1qBM5fxLho0zsArJe_g/viewform";

const events = [
  {
    name: "生日 & 求婚",
    icon: Flower,
    imgSrc: Party,
  },
  {
    name: "團體包場",
    icon: Hug,
    imgSrc: Enterprise,
  },
  {
    name: "特別訂製",
    icon: Box,
    imgSrc: Customization,
  },
];

const Row = ({ idx, name, icon, imgSrc }) => (
  <div className={`mb-12 md:mb-20 flex ${idx % 2 === 1 ? "md:flex-row-reverse" : ""} justify-center items-center gap-6 md:gap-16`}>
    <div className="flex flex-col md:px-8 items-center gap-4">
      <img src={icon} alt={name} className="w-[140px] md:w-[200px] 2xl:w-[280px]" />
      <Heading variant="h3">
        <span className="font-semibold">{name}</span>
      </Heading>
    </div>
    <div className="w-[200px] md:w-[500px] 2xl:w-[600px]">
      <img src={imgSrc} alt={name} className="w-full rounded object-cover md:object-contain aspect-square md:aspect-[3/2]"/>
    </div>
  </div>
)

const SpecialPage = () => {
  return (
    <Layout>
      <Seo {...metaData} />
      <div className="container mx-auto pt-24 pb-16 md:pt-32 md:pb-24">
        <div className="mb-24 flex flex-col items-center">
          <Heading variant="h1">
            QhAt Special
          </Heading>
          <p className="mt-8 mb-16 text-primary-heavy md:text-lg leading-loose">
            生日求婚、家族聚會、好友出遊、企業團建，來體驗一場最與眾不同的活動吧！任何客製化的驚喜歡迎來QhAt！
          </p>
          <LinkButton href={RESERVE_SPECIAL_LINK}>
            預約你的專屬活動
          </LinkButton>
        </div>
        <div className="px-8">
          {events.map((data, idx) => <Row key={data.name} idx={idx} {...data} /> )}
        </div>
      </div>
    </Layout>
  )
};

export default SpecialPage;