import React from "react"
import { Layout, Heading, GameCard, Seo } from "../components";
import Ramen from '../images/games/ramen-512w.webp'
import Prison from '../images/games/prison-512w.webp'
import IntoPhone from '../images/games/intophone-512w.webp'

const metaData = {
  title: "2022必玩密室逃脫！深夜拉麵鋪、獄罷不能、超機大冒險",
  description: "詭異溫馨的日式食堂、刺激驚險的監獄任務、網美場景的可愛冒險，全新場景、全新任務、全新任務，快來體驗！！！",
  image: process.env.ROOT_URL + 'banner.jpeg'
}

export const gameData = [
  {
    title: "深夜拉麵鋪", 
    imgSrc: Ramen, 
    hashtags: ["溫馨小品", "日式食堂"], 
    people: [2, 6], 
    duration: 60, 
    level: "2",
    href: "https://qhatex.boostime.me/activities/xy9t435i"
  },
  {
    title: "獄罷不能", 
    imgSrc: Prison, 
    hashtags: ["囚禁掙扎", "絕處逢生"], 
    people: [2, 6], 
    duration: 60, 
    level: "2",
    href: "https://qhatex.boostime.me/activities/neezbz3g"
  },
  {
    title: "超機大冒險", 
    imgSrc: IntoPhone, 
    hashtags: ["活潑可愛", "新手探索"], 
    people: [2, 6], 
    duration: 60, 
    level: "1",
    href: "https://qhatex.boostime.me/activities/otbdneq7"
  },
  {
    title: "", 
    imgSrc: null, 
    hashtags: [], 
    people: [], 
    duration: 0, 
    level: "",
    href: ""
  }
]

const GamesPage = () => {

  return (
    <Layout>
      <Seo {...metaData} />
      <div className="container mx-auto">
        <div className="py-24 md:py-32 flex flex-col items-center">
          <Heading variant="h1">
            QhAt Games
          </Heading>
          <p className="mt-8 mb-4 text-primary-heavy md:text-lg leading-7">
            最精彩的遊戲，盡在 QhAt 帽子烤工廠
          </p>
          <p className="text-primary-heavy md:text-lg leading-7">
            *遊戲最高難度為 Level 5 
          </p>
        </div>
        <div className="relative mx-8 py-16 md:py-32 border-t-2 border-primary-heavy">
          <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 md:px-16 bg-background">
            <Heading variant="h3">雙人成行</Heading>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-16 flex-wrap">
            {gameData.map((data, idx) => {
              if (idx > 2) return null;
              
              return <GameCard key={`${data.title}-${idx}`} {...data} />
            })}
          </div>
        </div>
        <div className="relative mx-8 py-16 md:py-32 border-t-2 border-primary-heavy">
          <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 md:px-16 bg-background">
            <Heading variant="h3">三人出遊</Heading>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-16 flex-wrap">
            {gameData.map((data, idx) => {
              if (idx <= 2) return null;
              
              return <GameCard key={`${data.title}-${idx}`} {...data} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default GamesPage;